
import { defineComponent, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import useDealerOverview from "@/hooks/rv/useDealerOverview";
import UploadFiles from "@/views/UserCar/Components/BatchUploadSubmit.vue";
import listUpload from "@/views/UserCar/Components/listUpload.vue";
import viewUpload from "@/views/UserCar/Components/previewModel.vue";
import appealModel from "@/views/UserCar/Components/appealModel.vue";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import batchInputModel from "@/views/UserCar/Components/batchInputModel.vue";
import downloadFile from "@/utils/rv/downloadFile";
import { message, Modal } from "ant-design-vue";
import { CheckCircleOutlined, ClockCircleOutlined, FileSearchOutlined } from '@ant-design/icons-vue';
import moment from "moment";
import {
  countTableSearch,
  countTableSearchTrande,
  getpageList,
  getpageListTrande,
  deleteBtn,
  deleteBtnTrande,
  batchBack,
  batchBackTrande,
  batchSubmit,
  batchSubmitTrande,
  ucMarketInfoBatchDelete,
  ucReplacementInfoBatchDelete,
  checkForVerifyCPO,
  checkForVerifyTradeIn,
} from "@/API/userCar";
const exportParams = ref<any>({});
const tableWidth = window.innerWidth;

const tableHeight = ref<number>(window.innerHeight - 435);
export default defineComponent({
  components: {
    UploadFiles,
    listUpload,
    viewUpload,
    appealModel,
    batchInputModel,
    CheckCircleOutlined,
    ClockCircleOutlined,
    FileSearchOutlined
  },
  setup() {
    const route = useRoute();
    const isSearch = ref<boolean>(false);
    const isSingle = ref<boolean>(false);
    const isSelect = ref<number>(route.query.type === "1" ? 1 : 0);
    const salesCard = reactive<any>([
      {
        year: new Date().getFullYear(),
        quarter: "Q1",
        totalCount: 0,
        unsubmittedCount: 0,
      },
      {
        year: new Date().getFullYear(),
        quarter: "Q2",
        totalCount: 0,
        unsubmittedCount: 0,
      },
      {
        year: new Date().getFullYear(),
        quarter: "Q3",
        totalCount: 0,
        unsubmittedCount: 0,
      },
      {
        year: new Date().getFullYear(),
        quarter: "Q4",
        totalCount: 0,
        unsubmittedCount: 0,
      },
    ]);
    // 头部参数信息
    const headerParams = ref<any>({
      quarter: " ",
      year: new Date().getFullYear(),
      unSubmit: null,
    });
    //分页
    const pagination = reactive({
      total: 0,
      currentPage: 1,
      pageSize: 10,
    });
    const { isOpenPanle, isOpen, activeKey } = useDealerOverview();
    // 审核状态
    const approvalStatusArray = reactive([
      {
        code: "0",
        msg: "未上传",
      },
      {
        code: "1",
        msg: "已上传未提交",
      },
      {
        code: "2",
        msg: "已提交",
      },
      {
        code: "d101",
        msg: "审核中",
      },
      {
        code: "d102",
        msg: "已申诉待核查",
      },
      {
        code: "6",
        msg: "审核完成",
      },
      {
        code: "109",
        msg: "提交已过期",
      },
    ]);
    const approlStatus = ref(null);
    // 审核结果
    const approvalResultArray = reactive([
      {
        code: "d001",
        msg: "未审核",
      },
      {
        code: "d004",
        msg: "申诉审核中",
      },
      {
        code: "d003",
        msg: "待申诉",
      },
      {
        code: "d005",
        msg: "审核未通过",
      },
      {
        code: "d002",
        msg: "审核通过",
      },
    ]);
    const approlResult = ref(null);
    const vinNo = ref(); //车架号
    const vinNoArr = ref<any>([]); //车架号数组
    const newVinNo = ref();
    const newVinNoArr = ref<any>([]);
    const carNo = ref(); //车牌号码
    // 二手车开票起止时间
    const invoiceStartDate = ref<unknown>("");
    const invoiceEndDate = ref<unknown>("");
    // 新车开票起止时间
    const newInvoiceDateFrom = ref<unknown>("");
    const newInvoiceDateTo = ref<unknown>("");
    // 过户起止时间字段
    const transformStartDate = ref<unknown>("");
    const transformEndDate = ref<unknown>("");
    // 提交日期起止时间
    const submitStartDate = ref<unknown>("");
    const submitEndDate = ref<unknown>("");
    const rowUpVin = reactive<any>({});
    type Key = ColumnProps["key"];
    const selectedRowKeys = ref([]);
    let selectedRows: any[] = [];
    const onSelectChange = (keys: [], rows: []) => {
      selectedRowKeys.value = keys;
      selectedRows = rows;
    };
    // CPO销售页签表头
    const columnsCPO = reactive<any>([
      {
        title: "卖主名称",
        dataIndex: "seller",
        align: "center",
        width: 260,
      },
      {
        title: "卖主身份证号/组织机构代码",
        dataIndex: "sellerCode",
        align: "center",
        width: 220,
      },
      {
        title: "认证日期",
        dataIndex: "certificationDate",
        align: "center",
        width: 100,
      },
      {
        title: "车架号",
        dataIndex: "vinNo",
        align: "center",
        width: 190,
      },
      { title: "系列", dataIndex: "series", align: "center", width: 300 },
      {
        title: "型号",
        dataIndex: "typeClass",
        align: "center",
        width: 100,
      },
      {
        title: "首次登记日期",
        dataIndex: "firstRegistDate",
        align: "center",
        width: 150,
      },

      {
        title: "新车保修起始日期",
        dataIndex: "startRepairDate",
        align: "center",
        width: 180,
      },

      {
        title: "买主名称",
        dataIndex: "buyerName",
        align: "center",
        width: 300,
      },
      {
        title: "保修编号",
        dataIndex: "repairCode",
        align: "center",
        width: 150,
      },
      {
        title: "认证证书编号",
        dataIndex: "certificateCode",
        align: "center",
        width: 150,
      },
      {
        title: "转移类型(过户/迁出)",
        dataIndex: "transferType",
        align: "center",
        width: 180,
        slots: { customRender: "transferType" },
      },
      {
        title: "二手车开票日期",
        dataIndex: "invoiceDate",
        align: "center",
        width: 180,
      },
      {
        title: "二手车过户日期(或临牌签发日期)",
        dataIndex: "transferDate",
        align: "center",
        width: 250,
      },
      {
        title: "提交日期",
        dataIndex: "submitDate",
        align: "center",
        width: 90,
      },
      {
        title: "申诉截止日",
        dataIndex: "appealDeadline",
        align: "center",
        width: 90,
        slots: { customRender: "appealDeadline" }
      },
      {
        title: "审核状态",
        dataIndex: "checkStatus",
        align: "center",
        width: 130,
        slots: { customRender: "checkStatus" },
      },
      {
        title: "审核结果",
        dataIndex: "checkResult",
        align: "center",
        width: 90,
        slots: { customRender: "checkResult" },
      },
      {
        title: "反馈信息",
        dataIndex: "feedbackMsg",
        align: "center",
        width: 210,
        slots: { customRender: "feedbackMsg" },
      },
      {
        title: "文件",
        dataIndex: "file",
        fixed: "right",
        slots: { customRender: "file" },
        align: "center",
        width: 550,
      },
      {
        title: "操作",
        dataIndex: "action",
        fixed: "right",
        slots: { customRender: "action" },
        align: "center",
        width: 200,
      },
    ]);
    // 置换页签的表头
    const columns = reactive<any>([
      {
        title: "品牌／系列",
        dataIndex: "series",
        align: "center",
        width: 230,
      },
      {
        title: "车型",
        dataIndex: "typeClass",
        align: "center",
        width: 270,
      },
      {
        title: "二手车VIN码",
        dataIndex: "usedCarVinNo",
        align: "center",
        width: 220,
      },
      {
        title: "车牌号码 (过户前)",
        dataIndex: "vehicleLicense",
        align: "center",
        width: 170,
      },
      {
        title: "首次登记日期",
        dataIndex: "firstRegistDate",
        align: "center",
        width: 150,
      },
      {
        title: "二手车过户日期",
        dataIndex: "transferDate",
        align: "center",
        width: 150,
      },
      {
        title: "车辆VIN码",
        dataIndex: "vehicleVinNo",
        align: "center",
        width: 220,
      },
      {
        title: "新车开票日期",
        dataIndex: "newInvoiceDate",
        align: "center",
        width: 110,
      },
      {
        title: "二手车卖主名称",
        dataIndex: "seller",
        align: "center",
        width: 120,
      },
      {
        title: "卖主身份证号/组织机构代码",
        dataIndex: "sellerCode",
        align: "center",
        width: 200,
      },
      {
        title: "和新车买主的关系",
        dataIndex: "relation",
        align: "center",
        width: 140,
      },
      {
        title: "新车买主名称",
        dataIndex: "buyerName",
        align: "center",
        width: 110,
      },
      {
        title: "买主身份证号/组织机构代码",
        dataIndex: "buyerCode",
        align: "center",
        width: 200,
      },
      {
        title: "提交日期",
        dataIndex: "submitDate",
        align: "center",
        width: 90,
      },
      {
        title: "申诉截止日",
        dataIndex: "appealDeadline",
        align: "center",
        width: 90,
        slots: { customRender: "appealDeadline" }
      },
      {
        title: "审核状态",
        dataIndex: "checkStatus",
        align: "center",
        width: 130,
        slots: { customRender: "checkStatus" },
      },
      {
        title: "审核结果",
        dataIndex: "checkResult",
        align: "center",
        width: 90,
        slots: { customRender: "checkResult" },
      },
      {
        title: "反馈信息",
        dataIndex: "feedbackMsg",
        align: "center",
        width: 210,
        slots: { customRender: "feedbackMsg" },
      },
      {
        title: "文件",
        dataIndex: "file",
        fixed: "right",
        slots: { customRender: "file" },
        align: "center",
        width: 550,
      },
      {
        title: "操作",
        dataIndex: "action",
        fixed: "right",
        slots: { customRender: "action" },
        align: "center",
        width: 200,
      },
    ]);

    const tableData = reactive<any>([]);
    const tableDataTrande = reactive<any>([]);

    const isInputVisible = ref(false);
    const isInputVisibleNew = ref(false);
    // 批量查询vinNO
    const batchQueryEvent = (type: string) => {
      if (type === "used") {
        isInputVisible.value = true;
      } else {
        isInputVisibleNew.value = true;
      }
    };

    watch(vinNoArr, (values) => {
      vinNo.value = values.length ? values[0] : "";
    });

    watch(newVinNoArr, (values) => {
      newVinNo.value = values.length ? values[0] : "";
    });
    // 上传清单
    const isListVisible = ref<boolean>(false);

    // 列表上传弹窗
    const listUp = () => {
      isListVisible.value = true;
    };
    const listClose = () => {
      isListVisible.value = false;
    };
    // 查看预览弹窗
    const viewList = ref<unknown>({});
    const isViewVisible = ref<boolean>(false);
    const viewClose = () => {
      isViewVisible.value = false;
    };
    // 申诉弹窗
    const isAppealVisible = ref<boolean>(false);
    const appealObj = ref<object>({});
    // 上传抽屉
    const isUploadVisible = ref<boolean>(false);
    const upLoad = () => {
      isSingle.value = false;
      isUploadVisible.value = true;
    };
    const closeBatch = () => {
      isUploadVisible.value = false;
    };

    // 处理批量撤回数据
    const handleBackArr = (params: any) => {
      const tempArr: any = reactive([]);
      params.forEach((ele: any) => {
        if (ele.checkStatus == 2) {
          tempArr.push(ele.id);
        }
      });
      return tempArr;
    };
    // 导出结果  cpo+置换
    const exportResultBtn = (params: any) => {
      if (isSelect.value == 0) {
        const fileName = `XS_(${moment(new Date()).format(
          "YYYYMMDDhhmmss"
        )}).xlsx`;
        downloadFile({
          url: "/rvapi/ucMarketInfo/export?type=1",
          method: "post",
          params: exportParams.value,
          fileName: fileName,
        });
      } else {
        const fileName = `ZH_(${moment(new Date()).format(
          "YYYYMMDDhhmmss"
        )}).xlsx`;
        downloadFile({
          url: "/rvapi/ucReplacementInfo/export?type=1",
          method: "post",
          params: exportParams.value,
          fileName: fileName,
        });
      }
    };
    // 处理返回值数据
    const handleHeaderArr = (arr = []) => {
      if (arr.length) {
        salesCard.length = 0;
        salesCard.push(
          {
            year: new Date().getFullYear(),
            quarter: "Q1",
            totalCount: 0,
            unsubmittedCount: 0,
          },
          {
            year: new Date().getFullYear(),
            quarter: "Q2",
            totalCount: 0,
            unsubmittedCount: 0,
          },
          {
            year: new Date().getFullYear(),
            quarter: "Q3",
            totalCount: 0,
            unsubmittedCount: 0,
          },
          {
            year: new Date().getFullYear(),
            quarter: "Q4",
            totalCount: 0,
            unsubmittedCount: 0,
          }
        );
        arr.forEach((ele: any) => {
          salesCard.forEach((item: any) => {
            if (ele.quarter == item.quarter) {
              item.year = ele.year;
              item.totalCount = ele.totalCount;
              item.unsubmittedCount = ele.unsubmittedCount;
            }
          });
          return salesCard;
        });
      } else {
        salesCard.length = 0;
        salesCard.push(
          {
            year: new Date().getFullYear(),
            quarter: "Q1",
            totalCount: 0,
            unsubmittedCount: 0,
          },
          {
            year: new Date().getFullYear(),
            quarter: "Q2",
            totalCount: 0,
            unsubmittedCount: 0,
          },
          {
            year: new Date().getFullYear(),
            quarter: "Q3",
            totalCount: 0,
            unsubmittedCount: 0,
          },
          {
            year: new Date().getFullYear(),
            quarter: "Q4",
            totalCount: 0,
            unsubmittedCount: 0,
          }
        );
      }
      return salesCard;
    };
    // 初始化页头统计区域
    const initHeaderCar = () => {
      if (isSelect.value == 0) {
        countTableSearch({}).then((res: any) => {
          handleHeaderArr(res);
        });
      } else {
        countTableSearchTrande({}).then((res: any) => {
          handleHeaderArr(res);
        });
      }
    };
    // 筛选时间选择器事件
    const dateChangeInvoice = (params: any) => {
      invoiceStartDate.value = params[0];
      invoiceEndDate.value = params[1];
    };
    // 新车开票
    const dateChangeInvoiceNew = (params: any) => {
      newInvoiceDateFrom.value = params[0];
      newInvoiceDateTo.value = params[1];
    };
    // 过户日期选择器事件
    const dateChangeTranstorm = (params: any) => {
      transformStartDate.value = params[0];
      transformEndDate.value = params[1];
    };

    // 提交日期选择器事件
    const dateChangeSubmit = (params: any) => {
      submitStartDate.value = params[0];
      submitEndDate.value = params[1];
    };

    // 重置筛选条件
    const resetFilter = () => {
      vinNo.value = "";
      vinNoArr.value.length = 0;
      newVinNo.value = "";
      newVinNoArr.value.length = 0;
      carNo.value = "";
      approlResult.value = null;
      approlStatus.value = null;
      transformStartDate.value = "";
      transformEndDate.value = "";
      submitStartDate.value = "";
      submitEndDate.value = "";
      invoiceStartDate.value = "";
      invoiceEndDate.value = "";
      newInvoiceDateFrom.value = "";
      newInvoiceDateTo.value = "";
      headerParams.value.quarter = " ";
      headerParams.value.unSubmit = null;
      headerParams.value.year = new Date().getFullYear();
    };
    // 初始化表格信息
    const getTableData = (params: any) => {
      initHeaderCar(); //初始化页头卡片信息
      // cpo销售
      if (isSelect.value == 0) {
        // cpo销售清单信息
        const tempObj = {
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          vehicleLicense: carNo.value,
          checkResult: approlResult.value,
          checkStatus: headerParams.value.unSubmit
            ? headerParams.value.unSubmit
            : approlStatus.value,
          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerCode: "",
          dealerId: "",
          size: pagination.pageSize,
          vinNos: vinNoArr.value.length
            ? vinNoArr.value
            : vinNo.value !== undefined
            ? [vinNo.value]
            : [],
          quarter: params.quarter,
          year: params.year,
        };
        exportParams.value = tempObj;
        getpageList(tempObj).then((res: any) => {
          tableData.length = 0;
          tableData.push(...res.content);
          pagination.pageSize = res.size;
          // pagination.currentPage =
          //     res.totalPages >= 1 ? res.totalPages - 1 : 0;
          pagination.total = res.totalElements;
        });
      } else {
        const tempObj1 = {
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          newInvoiceDateFrom: newInvoiceDateFrom.value,
          newInvoiceDateTo: newInvoiceDateTo.value,
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          usedCarVinNos: vinNoArr.value.length
            ? vinNoArr.value
            : vinNo.value !== undefined
            ? [vinNo.value]
            : [],
          vehicleVinNos: newVinNoArr.value.length
            ? newVinNoArr.value
            : [newVinNo.value],
          vehicleLicense: carNo.value,
          checkResult: approlResult.value,
          checkStatus: headerParams.value.unSubmit
            ? headerParams.value.unSubmit
            : approlStatus.value,
          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerCode: "",
          dealerId: "",
          size: pagination.pageSize,
          quarter: params.quarter,
          year: params.year,
        };
        exportParams.value = tempObj1;
        // 置换清单信息
        getpageListTrande(tempObj1).then((res: any) => {
          tableDataTrande.length = 0;
          tableDataTrande.push(...res.content);
          pagination.pageSize = res.size;
          // pagination.currentPage =
          //     res.totalPages >= 1 ? res.totalPages - 1 : 0;
          pagination.total = res.totalElements;
        });
      }
      selectedRowKeys.value = [];
      selectedRows = [];
    };

    const handleBatchDelete = () => {
      Modal.confirm({
        title: "提示",
        content: "是否确认批量删除?",
        okText: "确认",
        cancelText: "取消",
        onOk: async () => {
          if (isSelect.value == 0) {
            // CPO销售
            await ucMarketInfoBatchDelete({
              ...exportParams.value,
              idList: selectedRowKeys.value,
            });
            message.success("删除成功");
          } else {
            // 置换
            await ucReplacementInfoBatchDelete({
              ...exportParams.value,
              idList: selectedRowKeys.value,
            });
            message.success("删除成功");
          }
          getTableData({});
        },
      });
    };
    // 批量提交请求事件 cpo+置换
    const batchSub = () => {
      if (selectedRows.length === 0) {
        message.warning("请选择至少一条提交数据");
        return;
      }
      const idList = selectedRows.map((ele: any) => {
        return ele.id;
      });
      handleSubmitConfirm(idList, "确认提交审批?");
    };

    function handleSubmitConfirm(idList: any, content: string) {
      Modal.confirm({
        title: "提示",
        content,
        okText: "确认",
        cancelText: "取消",
        onOk() {
          if (isSelect.value == 0) {
            // 销售CPO
            batchSubmit({ idList, type: 1 }).then((res: any) => {
              getTableData({});
              message.success(`${res} 条记录提交成功！`);
            });
          } else {
            // 置换清单提交
            batchSubmitTrande({
              idList,
              type: 1,
            }).then((res: any) => {
              getTableData({});
              message.success(`${res} 条记录提交成功！`);
            });
          }
        },
      });
    }
    // cpo+置换 批量撤回请求事件
    const batchBackBtn = (params: any) => {
      if (selectedRows.length || params.length) {
        const subArr =
          params && params.length
            ? handleBackArr(params)
            : handleBackArr(selectedRows);
        if (subArr.length) {
          Modal.confirm({
            title: "提示",
            content: params.length ? "确认撤回？" : "确认批量撤回?",
            okText: "确认",
            cancelText: "取消",
            onOk() {
              if (isSelect.value == 0) {
                // cpo销售清单撤回
                batchBack(subArr).then(() => {
                  getTableData({});
                  message.success("成功");
                });
              } else {
                // 置换批量撤回
                batchBackTrande(subArr).then(() => {
                  getTableData({});
                  message.success("成功");
                });
              }
            },
            onCancel() {
              // message.success(
              //     subArr.length + '条记录取消撤回成功！'
              // );
            },
          });
        } else {
          message.warning("请选择可撤回数据");
        }
      } else {
        message.warning("请至少选择一条可撤回数据");
      }
    };
    // 行间事件预览
    const handleInvoiceBtn = (parma: any, num: number) => {
      if (isSelect.value == 1) {
        viewList.value = {
          id: parma.id,
          type: num,
          vinNo: parma.vehicleVinNo,
          types: isSelect.value, //是置换还是cpo销售
          operationType: 2,
        };
      } else {
        viewList.value = {
          id: parma.id,
          type: num,
          vinNo: parma.vinNo,
          types: isSelect.value, //是置换还是cpo销售
          operationType: 1,
        };
      }

      isViewVisible.value = true;
    };
    // 行间上传
    const rowUpload = (params: any) => {
      isUploadVisible.value = true;
      isSingle.value = true;
      Object.assign(rowUpVin, params);
    };
    // 行间删除 cpo+置换
    const rowDel = (params: any) => {
      if (isSelect.value == 0) {
        deleteBtn(params.id).then(() => {
          message.success("删除成功");
          getTableData({}); //刷新表格
        });
      } else {
        deleteBtnTrande(params.id).then(() => {
          message.success("删除成功");
          getTableData({}); //刷新表格
        });
      }
    };

    const rowSubmit = (record: any) => {
      handleSubmitConfirm([record.id], "确认提交？");
    };
    const rowBack = (params: any) => {
      batchBackBtn([params]);
    };

    //校验申诉截止时间
    const checkVerify = (params: any) => {
      const ckeckForVerify = {
        0: checkForVerifyCPO,
        1: checkForVerifyTradeIn,
      };
      return ckeckForVerify[isSelect.value](params.id)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    };
    // const vinForCheck = ref('')
    const rowAppeal = async (params: any) => {
      const status = await checkVerify(params);
      if (status == true) {
        appealObj.value = params;
        Object.assign(appealObj.value, { types: isSelect.value });
        isAppealVisible.value = true;
      }
      // isSelect.value === 1 ? columns : columnsCPO
    };

    // 查询事件
    const searchTable = (withYear = true) => {
      isSearch.value = true;
      pagination.currentPage = 1;
      const tempObj = {
        checkResult: approlResult.value,
        checkStatus: headerParams.value.unSubmit
          ? headerParams.value.unSubmit
          : approlStatus.value,
        invoiceDateFrom: invoiceStartDate.value,
        invoiceDateTo: invoiceEndDate.value,
        submitDateFrom: submitStartDate.value,
        submitDateTo: submitEndDate.value,
        transferDateFrom: transformStartDate.value,
        transferDateTo: transformEndDate.value,
        vinNos: vinNoArr.value.length
          ? vinNoArr.value
          : vinNo.value !== undefined
          ? [vinNo.value]
          : [],
        usedCarVinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
        vehicleLicense: carNo.value,
        size: pagination.pageSize,
        current: pagination.currentPage,
        year: withYear ? new Date().getFullYear() : undefined,
        quarter: withYear ? headerParams.value.quarter : undefined,
      };
      getTableData(tempObj);
      // 如果是点击的总计数字发起查询，全部清空，除年份和季度之外
      headerParams.value.unSubmit = null;
      // resetFilter(); //全部清空
      isOpen.value = false;
    };
    // 切换页签刷新页面
    const changeTab = (param: number) => {
      pagination.currentPage = 1;
      // 批量下载按钮不可用
      isSearch.value = false;
      isSelect.value = param;
      isOpen.value = false;
      resetFilter(); //重置筛选条件
      getTableData({}); //刷新表格
    };
    // 头部卡片蓝色数字点击数据穿透查询
    const showInfo = (params: any, type: number) => {
      isSearch.value = true;
      if (type == 1) {
        // 如果是点击的总计数字发起查询，全部清空，除年份和季度之外
        headerParams.value.unSubmit = null;
        resetFilter(); //全部清空
      } else {
        headerParams.value.unSubmit = 100;
      }
      headerParams.value.quarter = params.quarter;
      headerParams.value.year = params.year;
      pagination.currentPage = 1;
      getTableData(headerParams.value);
      resetFilter(); //全部清空
    };
    //更改分页页签
    const pageChange = (page: number) => {
      pagination.currentPage = page;
      getTableData({});
    };
    //改变每页大小
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = 1;
      getTableData({});
    };
    getTableData({}); //初始化列表数据

    const checkSubmit = (record: any) => {
      if (isSelect.value == 0) {
        if (
          record.checkStatus > 1 ||
          !record.invoice ||
          !record.registrationCertificate ||
          !record.certificationReport
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          record.checkStatus > 1 ||
          !record.invoice ||
          !record.registrationCertificate
        ) {
          return true;
        } else {
          return false;
        }
      }
    };

    const genCheckResult = (record: any) => {
      if (record.checkStatus !== 6) {
        if (record.appealTime === "") {
          return "未审核";
        } else {
          return "申诉审核中";
        }
      } else {
        if (record.checkResult === 1) {
          return "审核通过";
        } else {
          if (record.appealTime === "") {
            return "待申诉";
          } else {
            return "审核未通过";
          }
        }
      }
    };

    const genCheckStatus = (record: any) => {
      // console.log(record.checkStatus);
      // console.log(record.expired);
      if (record.expired !== "") {
        return "提交已过期";
      } else {
        if (record.checkStatus === 0) {
          return "未上传";
        } else if (record.checkStatus === 1) {
          return "已上传未提交";
        } else if (record.checkStatus === 2) {
          return "已提交";
        } else if (record.checkStatus === 6) {
          return "审核完成";
        } else {
          if (record.appealTime !== "") {
            return "已申诉待审核";
          } else {
            return "审核中";
          }
        }
      }
    };

    return {
      checkSubmit,
      tableWidth,
      tableHeight,
      isSelect,
      vinNo,
      carNo,
      changeTab,
      salesCard,
      showInfo,
      activeKey,
      isOpenPanle,
      isOpen,
      columns,
      columnsCPO,
      tableData,
      tableDataTrande,
      getTableData,
      isViewVisible,
      isAppealVisible,
      appealObj,
      viewList,
      isListVisible,
      viewClose,
      listUp,
      upLoad,
      listClose,
      closeBatch,
      onSelectChange,
      pageChange,
      pagination,
      selectedRowKeys,
      sizeChange,
      isUploadVisible,
      approvalStatusArray,
      approlStatus,
      approlResult,
      approvalResultArray,
      searchTable,
      resetFilter,
      handleInvoiceBtn,
      rowUpload,
      rowDel,
      rowSubmit,
      rowBack,
      rowAppeal,
      batchBackBtn,
      batchSub,
      handleBatchDelete,
      exportResultBtn,
      transformStartDate,
      transformEndDate,
      submitStartDate,
      submitEndDate,
      invoiceStartDate,
      invoiceEndDate,
      dateChangeInvoice,
      dateChangeInvoiceNew,
      dateChangeTranstorm,
      dateChangeSubmit,
      isSingle,
      rowUpVin,
      batchQueryEvent,
      isInputVisible,
      isInputVisibleNew,
      vinNoArr,
      isSearch,
      newInvoiceDateFrom,
      newInvoiceDateTo,
      newVinNo,
      newVinNoArr,
      genCheckResult,
      genCheckStatus,
      moment
    };
  },
});
